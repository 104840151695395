import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { dateFormatter } from '../../src/javascripts/date_formatter'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      dataField: 'users',
      search: true,
      columns: [
        {
          field: 'create_date',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('create_date', { scope: 'activerecord.attributes.user' }),
        },
        {
          field: 'gender',
          sortable: true,
          formatter: this.genderFormatter,
          title: I18n.t('gender', { scope: 'activerecord.attributes.user' }),
        },
        {
          field: 'fullname',
          sortable: false,
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.user' }),
        },
        {
          field: 'birthday',
          sortable: false,
          formatter: dateFormatter,
          title: I18n.t('birthday', { scope: 'activerecord.attributes.user' }),
        },
        {
          field: 'email',
          sortable: false,
          title: I18n.t('email', { scope: 'activerecord.attributes.user' }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/users/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  genderFormatter(value, row) {
    return row.human_gender
  }
}

// This function is a substitute for jquery's serialize.
// so you can use this function for your params and don't need to include jquery.
// How to use:
//   import { serialize } from '../src/javascripts/serialize'
//   const params = serialize(this.formTarget)
// Results:
//   display_filter=all&stay_begin=stay_begin_within_three_days
export function serialize(form) {
  var arr = []
  Array.prototype.slice.call(form.elements).forEach(function (field) {
    if (
      !field.name ||
      field.disabled ||
      ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1
    )
      return
    if (field.type === 'select-multiple') {
      Array.prototype.slice.call(field.options).forEach(function (option) {
        if (!option.selected) return
        arr.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(option.value))
      })
      return
    }
    if (['checkbox', 'radio'].indexOf(field.type) > -1 && !field.checked) return
    arr.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value))
  })
  return arr.join('&')
}

// This function converts a JSON object to a URL encoded string
// which matches the x-www-form-urlencoded style.
// How to use:
//   import { serializeJSON } from '../src/javascripts/serialize'
//   const params = serializeJSON({ "foo": { "bar": "baz", "bam": { "0": "sum", "1": "sim", "2": "sam"] } })
// Results:
//   foo[bar]=baz&foo[bam][]=sum&foo[baz][]=sim&foo[baz][]=sam
export function serializeJSON(json) {
  let array = iterateObjects(json)
  return array.flat(10).join('&')
}

function iterateObjects(objects, parent = '') {
  const array = []

  Object.entries(objects).forEach(function (object) {
    if (typeof object[1] == 'object') {
      const newParent = parent.length ? `${parent}[${object[0]}]` : object[0]

      array.push(iterateObjects(object[1], newParent))
    } else {
      array.push(nestedField(object, parent))
    }
  })

  return array
}

function nestedField(object, parent = '') {
  if (parent.length) {
    if (isNaN(object[0])) {
      // nested attribute
      return parent + '[' + encodeURIComponent(object[0]) + ']=' + encodeURIComponent(object[1])
    } else {
      // array attribute
      return parent + '[]=' + encodeURIComponent(object[1])
    }
  } else {
    // top level attribute
    return encodeURIComponent(object[0]) + '=' + encodeURIComponent(object[1])
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { serialize } from '../../src/javascripts/serialize'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table', 'form']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      queryParams: this.mergeFormParams,
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.commune' }),
      },
      columns: [
        {
          field: 'titleofcommune',
          sortable: true,
          formatter: this.linkFormatter,
          title: I18n.t('titleofcommune', { scope: 'activerecord.attributes.commune' }),
        },
        {
          field: 'address_street',
          title: I18n.t('address_street', { scope: 'activerecord.attributes.commune' }),
        },
        {
          field: 'address_zip',
          title: I18n.t('address_zip', { scope: 'activerecord.attributes.commune' }),
        },
        {
          field: 'address_country',
          title: I18n.t('address_country', { scope: 'activerecord.attributes.commune' }),
        },
        {
          field: 'municipality_code',
          title: I18n.t('municipality_code', { scope: 'activerecord.attributes.commune' }),
        },
        {
          field: 'telephone',
          title: I18n.t('telephone', { scope: 'activerecord.attributes.commune' }),
        },
        {
          field: 'email',
          title: I18n.t('email', { scope: 'activerecord.attributes.commune' }),
        },
      ],
    }
  }

  linkFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/communes/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  mergeFormParams(params) {
    params.hidden = document.querySelector('[name=hidden]').checked

    return params
  }

  filterCommunes() {
    const pageLimit = '&limit=' + document.querySelector('.page-size').innerText
    const searchInput = '&search=' + document.querySelector('.search-input').value
    const params = serialize(this.formTarget) + pageLimit + searchInput

    $(this.tableTarget).bootstrapTable('showLoading')

    fetch(`${this.tableTarget.dataset.url}?${params}`)
      .then((response) => response.json())
      .then((data) => {
        $(this.tableTarget).bootstrapTable('load', data)
        $(this.tableTarget).bootstrapTable('hideLoading')
      })
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.advantage' }),
      },
      columns: [
        {
          field: 'title',
          sortable: true,
          formatter: this.titleFormatter,
          title: I18n.t('title', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'description',
          sortable: true,
          formatter: this.descriptionFormatter,
          title: I18n.t('description', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'advantage_category.name',
          title: I18n.t('category', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'location_name',
          sortable: true,
          title: I18n.t('location_name', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'location_city',
          sortable: true,
          title: I18n.t('location_city', { scope: 'activerecord.attributes.advantage' }),
        },
        {
          field: 'date_start',
          sortable: true,
          formatter: this.dateFormatter,
          title: I18n.t('available_date', { scope: 'activerecord.attributes.advantage' }),
        },
      ],
    }
  }

  titleFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/accommodation/advantages/${row.uid}' class='text-secondary'>${row.title}</a>`
    )
  }

  descriptionFormatter(value, row) {
    if (!value) return
    return value.length > 30 ? `${value.substring(0, 27)}...` : value
  }

  dateFormatter(value, row) {
    if (row.date_start) {
      return `${I18n.l('date.formats.long', row.date_start)} - ${I18n.l(
        'date.formats.long',
        row.date_end
      )}`
    }
  }
}

import { Controller } from 'stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import tinymce from 'tinymce/tinymce'

export default class extends Controller {
  static targets = ['form', 'systemMail', 'text']

  connect() {
    initI18n()
    if (this.systemMailTarget.checked) this.toggleInputs()
  }

  toggleInputs() {
    const systemMail = this.systemMailTarget.checked
    const systemMailInputGroup = this.formTarget.querySelector('.mail_template_system_mail_key')

    if (systemMail) {
      systemMailInputGroup.classList.remove('d-none')
    } else {
      this.setFormValues()
      systemMailInputGroup.classList.add('d-none')
    }
  }

  apply(event) {
    const systemMailKey = event.target.value
    if (!systemMailKey.length) return

    const settings = I18n.t(systemMailKey, { scope: 'mail_template_settings' })
    const defaultMail = I18n.t(settings.default_mail)

    this.setFormValues(systemMailKey, settings.name, defaultMail.subject, defaultMail.text)
  }

  setFormValues(systemMailKey = '', name = '', subject = '', text = '') {
    this.formTarget.querySelector('#mail_template_system_mail_key').value = systemMailKey
    this.formTarget.querySelector('#mail_template_name').value = name
    this.formTarget.querySelector('#mail_template_subject').value = subject
    tinymce.get('mail_template_text').setContent(text)
  }
}

import 'select2'

const loadLanguage = function () {
  const locale = document.body.dataset.locale
  if (!locale) return

  return require(`select2/src/js/select2/i18n/${locale}`)
}

const defaultSelect2Options = function (partialOptions = {}) {
  return {
    language: loadLanguage(),
    width: '100%',
    allowClear: true,
    placeholder: ' ',
    ...partialOptions,
  }
}

const initSelect2Remote = function (element, modalContentElem = null) {
  // NOTE: Please define data-url, data-label-method and data-value-method; data-data-field defaults to 'rows'
  // returned data should be a hash like { rows: [{ value: 'foo', label: 'bar' }, ...] }
  const elementDataset = element.dataset
  const url = (elementDataset.url || location.pathname) + '.json'
  const dataField = elementDataset.dataField || 'rows'
  const labelMethod = elementDataset.labelMethod
  const valueMethod = elementDataset.valueMethod
  const requestParams = function (params) {
    return { search: params.term, limit: 10 }
  }
  const processResults = function (data) {
    const results = data[dataField].flatMap((object) => {
      return { id: object[valueMethod], text: object[labelMethod] }
    })
    return { results: results }
  }

  const remoteOptions = {
    minimumInputLength: 3,
    ajax: {
      delay: 500,
      url: url,
      data: requestParams,
      processResults: processResults,
    },
  }

  const options = Object.assign({}, remoteOptions, defaultSelect2Options({ dropdownParent: modalContentElem }))
  window.jQuery(element).select2(options)
}

export function initSelect2(modalContent = null) {
  const modalContentElem = modalContent !== null ? $(modalContent) : null
  window.jQuery('.select2-container').remove()
  window.jQuery('.select2').select2(defaultSelect2Options({ dropdownParent: modalContentElem }))
  window.jQuery('.select2-with-max1').select2(defaultSelect2Options({ dropdownParent: modalContentElem,  maximumSelectionLength: 1}))
  window.jQuery('.select2-no-clear').select2(defaultSelect2Options({ allowClear: false, dropdownParent: modalContentElem }))
  document.querySelectorAll('.select2-remote').forEach((element) => {
    initSelect2Remote(element, modalContentElem);
  })

  // dispatch native change event on un-/select and clear to trigger stimulus actions
  window
    .jQuery('.select2, .select2-remote')
    .on('select2:select select2:unselect select2:clear', (event) => {
      const change = new Event('change', { bubbles: true })
      event.target.dispatchEvent(change)
    })
  // dispatch native change event on un-/select and clear to trigger stimulus actions
  window
    .jQuery('.select2-no-clear, .select2-no-clear-remote')
    .on('select2:select select2:unselect select2:clear', (event) => {
      const change = new Event('change', { bubbles: true })
      event.target.dispatchEvent(change)
    })
}

import { Controller } from 'stimulus'
import { csrfHeader } from '../../src/javascripts/csrf_header'
import { dateFormatter } from '../../src/javascripts/date_formatter'
import $ from 'jquery'

export default class extends Controller {
  connect() {}

  onChangeRegistrationNumber(params) {
    const registrationNumber = params.target.value
    let headers = csrfHeader()
    headers = { 'Content-Type': 'application/json', Accept: 'application/json', ...headers }
    if (registrationNumber !== '') {
      const body = {
        redemption: {
          registration_number: registrationNumber,
        },
      }
      fetch('/manual_registrations/redemptions/get_manual_registration.json', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            if (response.redirected) {
              return { redirected: true, url: response.url }
            } else {
              const isJson = response.headers.get('content-type')?.includes('application/json')
              if (isJson) {
                return response.json()
              }
            }
          } else throw response.json()
        })
        .then((data) => {
          if (data) {
            //Recreate the options of the select
            const manualRegistration = data.result
            if (manualRegistration) {
              $('#manual_registration_id').val(manualRegistration.uid)
              $('#status').val(manualRegistration.formatted_status)
              $('#accommodation_provider_name').val(
                manualRegistration.accommodation_provider_formatted_name
              )
              $('#issued_date').val(I18n.l('date.formats.default', manualRegistration.issued_date))
              $('#departure_date').val(
                I18n.l('date.formats.default', manualRegistration.departure_date)
              )
              if (manualRegistration.additional_dependents_adults > 0) {
                $('#additional_dependents_adults').val(
                  manualRegistration.additional_dependents_adults
                )
              }
              if (manualRegistration.additional_dependents_children > 0) {
                $('#additional_dependents_children').val(
                  manualRegistration.additional_dependents_children
                )
              }
              $('#arrival_date').val(dateFormatter(manualRegistration.arrival_date))
              $('#total_cost').val(manualRegistration.total_cost)
              $('#firstname').val(manualRegistration.firstname)
              $('#birthdate').val(I18n.l('date.formats.default', manualRegistration.birthdate))
              $('#lastname').val(manualRegistration.lastname)
              $('#country').val(manualRegistration.country)
              $('#address').val(manualRegistration.address)
              $('#nationality').val(manualRegistration.nationality)
              $('#zip').val(manualRegistration.zip)
              $('#identification_number').val(manualRegistration.identification_number)
              $('#area').val(manualRegistration.area)
              $('#accommodation_number').val(manualRegistration.accommodation_number)
              $('#accommodation_address').val(manualRegistration.accommodation_address)
              $('#accommodation_name').val(manualRegistration.accommodation_name)
              $('#accommodation_zip').val(manualRegistration.accommodation_zip)
              $('#accommodation_type').val(manualRegistration.accommodation_type)
              $('#accommodation_area').val(manualRegistration.accommodation_area)
            }
          }
          if (!data || !data.result) {
            window.location.reload()
          }
        })
        .catch((error) => {
          alert(error)
        })
    } else {
      $('#accommodation_id option:first-child').nextAll().remove()
    }
  }
}

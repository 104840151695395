import { Controller } from 'stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['select']

  connect() {
    initI18n()
    this.bindSelectEvent()
  }

  bindSelectEvent() {
    $(this.selectTarget).on('change.select2', (event) => {
      window.open(`/admin/widgets/${event.target.value}`, '_blank')
    })
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.payment_method' }),
      },
      columns: [
        {
          field: 'titleofcommune',
          title: I18n.t('one', { scope: 'activerecord.models.commune' }),
        },
        {
          field: 'brand',
          sortable: true,
          formatter: this.brandFormatter,
          title: I18n.t('brand', { scope: 'activerecord.attributes.payment_method' }),
        },
        {
          field: 'entity_id',
          sortable: true,
          title: I18n.t('entity_id', { scope: 'activerecord.attributes.payment_method' }),
        },
        {
          field: 'entity_password',
          sortable: true,
          title: I18n.t('entity_password', { scope: 'activerecord.attributes.payment_method' }),
        },
      ],
    }
  }

  brandFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/payment_methods/${row.uid}/edit' class='text-secondary'>${row.brand}</a>`
    )
  }
}

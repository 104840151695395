import { Controller } from 'stimulus'

export default class extends Controller {
  copyText(event) {
    const element = event.target
    const originalValue = element.value
    if (originalValue === I18n.t('copied')) return

    element.select()
    element.setSelectionRange(0, 99999)

    document.execCommand('copy')

    this.displaySuccess(element, originalValue)
  }

  displaySuccess(element, originalValue) {
    element.classList.add('text-secondary')
    element.value = I18n.t('copied')
    setTimeout(() => {
      element.classList.remove('text-secondary')
      element.value = originalValue
    }, 1000)
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { booleanIcon } from '../../src/javascripts/boolean_icon'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('user_commune', { scope: 'activerecord.models' }),
      },
      columns: [
        {
          field: 'fullname',
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.user_commune' }),
        },
        {
          field: 'email',
          title: I18n.t('email', { scope: 'activerecord.attributes.user_commune' }),
        },
        {
          field: 'telephone',
          title: I18n.t('telephone', { scope: 'activerecord.attributes.user_commune' }),
        },
        {
          field: 'cellphone',
          title: I18n.t('cellphone', { scope: 'activerecord.attributes.user_commune' }),
        },
        {
          field: 'admin?',
          sortable: true,
          formatter: booleanIcon,
          title: I18n.t('admin', { scope: 'activerecord.attributes.user_commune' }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/user_communes/${row.uid}' class='text-secondary'>${row.fullname}</a>`
    )
  }
}

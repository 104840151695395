import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.control_station' }),
      },
      columns: [
        {
          field: 'stationid',
          sortable: true,
          formatter: this.stationIdFormatter,
          title: I18n.t('stationid', { scope: 'activerecord.attributes.control_station' }),
        },
        {
          field: 'type',
          sortable: true,
          title: I18n.t('type', { scope: 'activerecord.attributes.control_station' }),
        },
        {
          field: 'login',
          sortable: true,
          title: I18n.t('login', { scope: 'activerecord.attributes.control_station' }),
        },
      ],
    }
  }

  stationIdFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/control_stations/${row.uid}/edit' class='text-secondary'>${row.stationid}</a>`
    )
  }
}

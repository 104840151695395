import flatpickr from 'flatpickr'
import 'flatpickr/dist/l10n/de.js'

export function initDatepicker() {
  flatpickr('.datepicker', {
    locale: I18n.locale,
    dateFormat: 'd.m.Y',
    allowInput: true,
  })
}

document.addEventListener('turbolinks:load', () => {
  initDatepicker()
})

import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    this.uploadEventListener()
  }

  uploadEventListener() {
    $('.custom-file-input').on('change', function () {
      var fileName = $(this).val().split('\\').pop()
      $(this).siblings('.custom-file-label').addClass('selected').html(fileName)
    })
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { booleanIcon } from '../../src/javascripts/boolean_icon'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table', 'form', 'loadingIndicator']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      search: false,
      dataField: 'annual_card_users',
      queryParams: this.mergeFormParams,
      exportOptions: {
        fileName: I18n.t('user', { scope: 'annual_cards' }),
      },
      columns: [
        {
          field: 'annual_card_key',
          sortable: true,
          formatter: this.showLink,
          title: I18n.t('annual_card_key', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'human_gender',
          sortable: true,
          title: I18n.t('gender', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'title',
          sortable: true,
          formatter: this.titleFormatter,
          title: I18n.t('title', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'firstname',
          sortable: false,
          title: I18n.t('firstname', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'lastname',
          sortable: false,
          title: I18n.t('lastname', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'residence',
          sortable: false,
          title: I18n.t('residence', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'residence_type',
          sortable: true,
          formatter: this.residenceTypeFormatter,
          title: I18n.t('residence_type', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'notification_type',
          sortable: true,
          formatter: this.notificationTypeFormatter,
          title: I18n.t('notification_type', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'state',
          sortable: true,
          formatter: (value, row) => this.stateFormatter(value, row, this),
          title: I18n.t('state', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'taxcard.stay_begin',
          sortable: false,
          formatter: (value, row) => this.annualTaxcardFormatter(value, row, this),
          title: I18n.t('ticket_is_annual', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'taxcard.payment_type',
          sortable: false,
          formatter: this.paymentTypeFormatter,
          title: I18n.t('payment_type', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'suspend_state',
          sortable: true,
          formatter: this.suspendStateFormatter,
          title: I18n.t('suspend_state', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'complete',
          sortable: false,
          formatter: booleanIcon,
          title: I18n.t('complete', { scope: 'annual_cards.filters' }),
        },
      ],
    }
  }

  showLink(value, row) {
    return DOMPurify.sanitize(
      `<a href='/annual_cards/annual_card_users/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  titleFormatter(value) {
    if (value) return value
    else return '-'
  }

  stateFormatter(value, _row, _controller) {
    if (value) return I18n.t(value, { scope: 'activerecord.attributes.annual_card_user.states' })
  }

  annualTaxcardFormatter(value, _row, _controller) {
    if (value) return value.split('-')[0]
  }

  modalLink(modalUrl) {
    const title = I18n.t('assign_annual_taxcard', { scope: 'taxcards' })
    return `<a data-action="modals#renderModal" title="${title}" data-url="${modalUrl}" class="btn btn-sm btn-outline-warning"><i class='fal fa-exchange'></i></a>`
  }

  notificationTypeFormatter(value) {
    if (value)
      return I18n.t(value, { scope: 'activerecord.attributes.annual_card_user.notification_types' })
  }

  residenceTypeFormatter(value) {
    if (value)
      return I18n.t(value, { scope: 'activerecord.attributes.annual_card_user.residence_types' })
  }

  suspendStateFormatter(value) {
    if (value)
      return I18n.t(value, { scope: 'activerecord.attributes.annual_card_user.suspend_states' })
  }

  paymentTypeFormatter(value) {
    if (value) return I18n.t(value, { scope: 'activerecord.attributes.transaction.payment_types' })
  }

  mergeFormParams(params) {
    params.state = $('#state').val()
    params.notification_type = $('#notification_type').val()
    params.state_changed = $('#state_changed').val()
    params.annual_card_year = $('#annual_card_year').val()
    params.employee_area = $('#employee_area').val()
    params.accommodation_type = $('#accommodation_type').val()
    params.residence_type = $('#residence_type').val()
    params.residence = $('#residence').val()
    params.city = $('#city').val()
    params.search = $('#search').val()
    params.todo = $('[name=todo]:checked')[0].value

    return params
  }

  addFilterPreset(_event) {
    const name = prompt(I18n.t('enter_preset_name', { scope: 'annual_cards' }))
    if (name) {
      const body = {
        annual_card_filter_preset: this.mergeFormParams({ name: name }),
      }
      const csrfToken = document.querySelector("[name='csrf-token']").content

      fetch('/annual_card_filter_presets', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((_response) => {
        location.reload()
      })
    }
  }

  fetchFilterPreset(event) {
    const params = JSON.parse(event.target.dataset.value)
    this.addRemoteResidenceOption(params.residence)
    this.setFormParams(params)
    this.filterUsers()
  }

  setFormParams(params) {
    for (const key in params) {
      $(`#${key}`).val(params[key]).trigger('change')
    }
  }

  addRemoteResidenceOption(residence) {
    const option = new Option(residence, residence)
    const select2ResidenceElement = document.getElementById('residence')
    select2ResidenceElement.append(option)
  }

  search(event) {
    event.preventDefault()
    this.filterUsers()
  }

  resetSearch(event) {
    $('#search').val('')
    this.search(event)
  }

  filterUsers() {
    const limit = '&limit=' + document.querySelector('.page-size').innerText
    const params = $(this.formTarget).serialize() + limit
    this.loadingIndicatorTarget.classList.remove('d-none')
    fetch('/annual_cards/annual_card_users.json?' + params)
      .then((response) => {
        this.loadingIndicatorTarget.classList.add('d-none')
        if (response.ok) return response.json()
        else throw response.json()
      })
      .then((data) => {
        window.jQuery(this.tableTarget).bootstrapTable('load', data)
      })
      .catch((error) => {
        Promise.resolve(error).then((message) => alert(message.error))
      })
  }
}

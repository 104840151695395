import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons.min'
import 'tinymce/plugins/paste/plugin.min'
import 'tinymce/plugins/link/plugin.min'

document.addEventListener 'turbolinks:load', ->
  tinymce.remove()

  tinymce.init(
    selector: '.tinymce',
    skin: false,
    min_height: 500,
    width: '100%',
    content_style: "body { font-family: Roboto-Light; }",
  )

  tinymce.init(
    selector: '.tinymce-pdf',
    skin: false,
    min_height: 500,
    width: '100%',
    content_style: "body { font-family: Roboto-Light; }",
    menubar: false,
    toolbar: 'bold italic | paste',
    plugins: 'paste',
  )

  tinymce.init(
    selector: '.tinymce-info',
    skin: false,
    min_height: 500,
    width: '100%',
    content_style: "body { font-family: Roboto-Light; }",
    plugins: 'link, paste',
    toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link'
  )

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.accommodation' }),
      },
      columns: [
        {
          field: 'name',
          sortable: true,
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.accommodation' }),
        },
        {
          field: 'accommodation_type.name',
          title: I18n.t('accommodation_type', { scope: 'activerecord.attributes.accommodation' }),
        },
        {
          field: 'accommodation_provider.name',
          // NOTE: postpone assigning agency button for user_accommodations
          // formatter: this.accommodationProviderFormatter,
          title: I18n.t('one', { scope: 'activerecord.models.accommodation_provider' }),
        },
        {
          field: 'street',
          sortable: true,
          title: I18n.t('street', { scope: 'activerecord.attributes.accommodation' }),
        },
        {
          field: 'zip',
          sortable: true,
          title: I18n.t('zip', { scope: 'activerecord.attributes.accommodation' }),
        },
        {
          field: 'city',
          sortable: true,
          title: I18n.t('city', { scope: 'activerecord.attributes.accommodation' }),
        },
        {
          field: 'accommodation_id',
          sortable: true,
          title: I18n.t('accommodation_id', { scope: 'activerecord.attributes.accommodation' }),
        },
        {
          field: 'district.name',
          title: I18n.t('district', { scope: 'activerecord.attributes.accommodation' }),
        },
      ],
    }
  }

  accommodationProviderFormatter(value, row) {
    return DOMPurify.sanitize(`
      <a class='btn btn-sm btn-outline-secondary float-right' data-action='modals#renderModal' data-url='/accommodations/${row.uid}/accommodation_modals/agency'><i class='fal fa-exchange'></i></a>
      ${value}
    `)
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='${location.href}/${row.uid}' class='text-secondary'>${row.name}</a>`
    )
  }
}

import { Controller } from 'stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['linkButton', 'fullLink']

  connect() {
    initI18n()
    this.bindEvents()
  }

  bindEvents() {
    this.linkButtonTarget.addEventListener('click', (_event) => {
      this.linkButtonIcon.classList.toggle('fa-external-link')
      this.linkButtonIcon.classList.toggle('fa-times')
      this.fullLinkTarget.classList.toggle('show')
    })
  }

  get linkButtonIcon() {
    return this.linkButtonTarget.querySelector('i')
  }
}

import DOMPurify from 'dompurify'
import { dateFormatter } from '../../src/javascripts/date_formatter'
import ApiKeyController from '../api_keys_controller'

export default class extends ApiKeyController {
  static targets = ['table', 'form']

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      showExport: false,
      search: true,
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.api_key' }),
        ignoreColumn: 'actions',
      },
      columns: [
        {
          field: 'name',
          sortable: true,
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'accommodation',
          sortable: true,
          formatter: this.accommodationFormatter,
          title: I18n.t('accommodation', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'api_key',
          sortable: true,
          formatter: this.apiKeyFormatter,
          title: I18n.t('api_key', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'create_date',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('create_date', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'create_user',
          sortable: true,
          formatter: this.createUserFormatter,
          title: I18n.t('create_userid', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'origins',
          cellStyle: () => {
            return { css: { 'overflow-wrap': 'anywhere' } }
          },
          sortable: true,
          formatter: this.originsFormatter,
          title: I18n.t('origins', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'offline_payment_allowed',
          sortable: true,
          formatter: this.offlinePaymentFormatter,
          title: I18n.t('offline_payment_allowed', { scope: 'activerecord.attributes.widget' }),
        },
        {
          field: 'action',
          formatter: (value, row) => this.actionsFormatter(this, row),
        },
      ],
    }
  }

  createUserFormatter(value, row) {
    if (!value) return '-'

    return DOMPurify.sanitize(
      `<a href='/accommodation/user_accommodations/${value.uid}' class='text-secondary'>${value.firstname} ${value.lastname}</a>`
    )
  }

  accommodationFormatter(value, row) {
    if (value == null) {
      return '-'
    }
    return DOMPurify.sanitize(
      `<a href='/accommodations/${value.uid}' class='text-secondary'>${value.name || '-'}</a>`
    )
  }

  actionsFormatter(controller, row) {
    const pdfButton = controller.pdfButton(row.id)

    return `<div class="btn-group">${pdfButton}</div>`
  }

  pdfButton(printId) {
    if (printId === null) return ''

    const url = `/accommodation/api_keys/${printId}/widget.pdf`
    const title = I18n.t('print_widget', { scope: 'api_keys' })

    return `<a href="${url}" title='${title}' target="_blank" class="btn btn-sm btn-secondary"><i class="fal fa-download"></i></a>`
  }
}

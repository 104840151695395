import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { dateFormatter } from '../../src/javascripts/date_formatter'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      dataField: 'transactions',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.transaction' }),
      },
      columns: [
        {
          field: 'titleofcommune',
          title: I18n.t('one', { scope: 'activerecord.models.commune' }),
        },
        {
          field: 'uid',
          sortable: true,
          title: I18n.t('id', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'name',
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'create_date',
          formatter: dateFormatter,
          title: I18n.t('create_date', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'order_id',
          sortable: true,
          title: I18n.t('order_id', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'taxcard.stay_begin',
          sortable: false,
          formatter: dateFormatter,
          title: I18n.t('stay_begin', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'taxcard.stay_end',
          sortable: false,
          formatter: dateFormatter,
          title: I18n.t('stay_end', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'taxcards_count',
          sortable: false,
          title: I18n.t('cards', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'price',
          sortable: true,
          formatter: this.moneyFormatter,
          title: I18n.t('price', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'payment_method.brand',
          formatter: this.paymentMethodFormatter,
          title: I18n.t('payment_type', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'status',
          sortable: true,
          formatter: this.statusFormatter,
          title: I18n.t('status', { scope: 'activerecord.attributes.transaction' }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/transactions/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  moneyFormatter(value, row) {
    return value.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
    })
  }

  statusFormatter(status, row) {
    if (!status) return
    const humanStatus = I18n.t(status, { scope: 'activerecord.attributes.transaction.states' })
    if (['approval_needed', 'cancellation_requested'].includes(status)) {
      return `<a href='/admin/taxcards/${row.taxcard.uid}' class='text-secondary'>${humanStatus} <i class="fal fa-question"></i></a>`
    } else if (['approval_rejected', 'cancellation_rejected'].includes(status)) {
      return `${humanStatus} <i class="fal fa-times text-warning"></i>`
    } else if (['approved', 'cancelled'].includes(status)) {
      return `${humanStatus} <i class="fal fa-check text-secondary"></i>`
    } else {
      return humanStatus
    }
  }

  paymentMethodFormatter(value, row) {
    if (value) return value
    if (row.transferred)
      return I18n.t('transferred', { scope: 'activerecord.attributes.transaction' })

    const paymentsLabel = I18n.t('other', { scope: 'activerecord.models.payment' })
    return `<i class='small'>${I18n.t('empty', { scope: 'errors', model: paymentsLabel })}</i>`
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.mail_template' }),
      },
      columns: [
        {
          field: 'titleofcommune',
          title: I18n.t('commune', { scope: 'activerecord.attributes.mail_template' }),
        },
        {
          field: 'name',
          sortable: true,
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.mail_template' }),
        },
        {
          field: 'subject',
          sortable: true,
          title: I18n.t('subject', { scope: 'activerecord.attributes.mail_template' }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/mail_templates/${row.uid}/edit' class='text-secondary'>${row.name}</a>`
    )
  }
}

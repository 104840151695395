import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import { serialize } from '../src/javascripts/serialize'
import { moneyFormatter } from '../src/javascripts/money_formatter'
import { dateFormatter } from '../src/javascripts/date_formatter'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table', 'form']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      dataField: 'transactions',
      queryParams: (params) => this.mergeFormParams(params, this.formParams),
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.transaction' }),
      },
      columns: [
        {
          field: 'name',
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'create_date',
          formatter: dateFormatter,
          title: I18n.t('create_date', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'order_id',
          sortable: true,
          title: I18n.t('order_id', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'taxcard.stay_begin',
          sortable: false,
          formatter: dateFormatter,
          title: I18n.t('stay_begin', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'taxcard.stay_end',
          sortable: false,
          formatter: dateFormatter,
          title: I18n.t('stay_end', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'taxcards_count',
          sortable: false,
          title: I18n.t('cards', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'price',
          sortable: true,
          formatter: (value, row) => moneyFormatter(value),
          title: I18n.t('price', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'status',
          sortable: true,
          formatter: this.statusFormatter,
          title: I18n.t('status', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'payment_method.brand',
          formatter: this.paymentMethodFormatter,
          title: I18n.t('another', { scope: 'activerecord.models.payment_method' }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/transactions/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  statusFormatter(status, row) {
    if (!status) return
    const humanStatus = I18n.t(status, { scope: 'activerecord.attributes.transaction.states' })
    if (['approval_needed', 'cancellation_requested'].includes(status)) {
      return `<a href='/taxcards/${row.taxcard.uid}' class='text-secondary'>${humanStatus} <i class="fal fa-question"></i></a>`
    } else if (['approval_rejected', 'cancellation_rejected'].includes(status)) {
      return `${humanStatus} <i class="fal fa-times text-warning"></i>`
    } else if (['approved', 'cancelled'].includes(status)) {
      return `${humanStatus} <i class="fal fa-check text-secondary"></i>`
    } else {
      return humanStatus
    }
  }

  paymentMethodFormatter(value, row) {
    if (value) return value
    if (row.transferred)
      return I18n.t('transferred', { scope: 'activerecord.attributes.transaction' })

    const humanPaymentStyle = I18n.t(row.payment_type, {
      scope: 'activerecord.attributes.transaction.payment_types',
    })
    return humanPaymentStyle
  }

  mergeFormParams(params, formParams) {
    params.stay_begin_from = document.querySelector('#stay_begin_from').value
    params.stay_begin_to = document.querySelector('#stay_begin_to').value
    params.status = document.querySelector('[name=status]:checked').value
    return params
  }

  filterTransactions() {
    const pageLimit = '&limit=' + document.querySelector('.page-size').innerText
    const searchInput = '&search=' + document.querySelector('.search-input').value
    const params = serialize(this.formTarget) + pageLimit + searchInput
    $(this.tableTarget).bootstrapTable('showLoading')
    fetch('/transactions.json?' + params)
      .then((response) => response.json())
      .then((data) => {
        $(this.tableTarget).bootstrapTable('load', data)
        $(this.tableTarget).bootstrapTable('hideLoading')
      })
  }
}

import { Controller } from 'stimulus'
import { moneyFormatter } from '../../src/javascripts/money_formatter'

export default class extends Controller {
  static targets = ['specialCondition', 'originalPrice', 'originalPriceNoFormat', 'newPrice', 'discount']

  connect() {
    this.loadCollection()
    this.specialConditionTarget.addEventListener('change', (event) => this.calculatePrice(event))
    this.calculatePrice()
  }

  loadCollection() {
    const controller = this
    fetch('/special_conditions.json?sort=title&order=desc')
      .then((response) => response.json())
      .then((data) => {
        data.rows.forEach((specialCondition) => controller.addOptionToTarget(specialCondition))
      })
  }

  addOptionToTarget(specialCondition) {
    const option = new Option(specialCondition.title_and_gdb, specialCondition.uid)
    if (
      option.value ==
      this.specialConditionTarget.parentElement
        .getElementsByClassName('select')[0]
        .getAttribute('value')
    ) {
      this.displayValuesForSelected(option, specialCondition)
    }
    option.dataset.discount = specialCondition.discount
    this.specialConditionTarget.append(option)
  }

  displayValuesForSelected(option, specialCondition) {
    option.setAttribute('selected', 'selected')
    this.discountTarget.innerHTML = `${specialCondition.discount} %`
    let price = this.originalPrice
    price = price - price * (specialCondition.discount / 100)
    this.setNewPrice(price)
  }

  calculatePrice(_event) {
    let discount = this.discount
    let price = this.originalPrice

    if (discount === undefined) {
      discount = '-'
    } else {
      price = price - price * (discount / 100)
      discount = `${discount} %`
    }

    this.setNewPrice(price)
    this.discountTarget.innerHTML = discount
  }

  setNewPrice(price) {
    if (this.newPriceTarget instanceof HTMLInputElement) {
      this.newPriceTarget.value = price
    } else {
      this.newPriceTarget.innerHTML = moneyFormatter(price)
    }
  }

  get discount() {
    return this.specialConditionTarget.querySelector(':checked').dataset.discount
  }

  get originalPrice() {
    return parseFloat(this.originalPriceNoFormatTarget.innerHTML)
  }
}

export function booleanIcon(value) {
  const icon = value ? 'check' : 'times'

  return `<i class='fal fa-${icon}'></i>`
}

export function dehydrateBooleanIcon(value) {
  const matchData = value.match(/(<i class="fal fa-)(.*)("><\/i>)/)
  const icon = (matchData && matchData[2]) || ''
  switch (icon) {
    case 'check':
      return 'Ja'
    case 'times':
      return 'Nein'
    default:
      return value
  }
}

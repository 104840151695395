import { Controller } from 'stimulus'

// USAGE:
// on wrapper
//   attach controller:
//     data-controller='input-toggle'
//   enable toggling on page rendering:
//     data-default-value="#{@object.value}"
// on toggler input:
//   when checkboxes/radio buttons (checked property):
//     data-action: 'change->input-toggle#booleanToggle'
//   when select/input fields:
//     data-action: 'change->input-toggle#valueToggle'
// on (toggable) target (mutlple targets supported)
//   define input:
//     data-target='input-toggle.input'
//   and define value which should trigger toggling:
//     data-active-value='false' / data-active-value='some_value'
export default class extends Controller {
  static targets = ['input']

  connect() {
    const defaultValue = this.element.dataset.defaultValue
    if (defaultValue) this.toggleTargets(defaultValue)
  }

  valueToggle(event) {
    const selectedValue = event.target.value
    this.toggleTargets(selectedValue)
  }

  booleanToggle(event) {
    const selectedValue = event.target.checked.toString()
    this.toggleTargets(selectedValue)
  }

  toggleTargets(selectedValue) {
    this.inputTargets.forEach((element) => {
      if (element.dataset.activeValue === selectedValue) {
        element.classList.remove('d-none')
      } else {
        element.classList.add('d-none')
      }
    })
  }
}

import 'bootstrap-table/dist/bootstrap-table.min.js'
import 'bootstrap-table/dist/locale/bootstrap-table-de-DE.min.js'
import '../../../../vendor/assets/javascripts/tableExport.min.js'
import 'bootstrap-table/dist/extensions/export/bootstrap-table-export.min.js'
import 'bootstrap-table/src/extensions/page-jump-to/bootstrap-table-page-jump-to'
import { dehydrateBooleanIcon } from './boolean_icon.js'
import DOMPurify from 'dompurify'
import XLSX from 'xlsx'
window.XLSX = XLSX

const defaultOptions = {
  pagination: true,
  pageSize: 25,
  sidePagination: 'server',
  search: true,
  searchTimeOut: 1500,
  sortReset: true,
  showColumns: true,
  iconsPrefix: 'fal',
  buttonsClass: 'outline-secondary',
  showJumpTo: true,
  showJumpToByPages: 5,
  formatJumpTo: () => {
    return 'Los'
  },
  showExport: true,
  exportDataType: 'all',
  exportTypes: ['csv', 'xlsx'],
  exportOptions: {
    onCellHtmlData(_cell, _rowIndex, _colIndex, htmlData) {
      return DOMPurify.sanitize(dehydrateBooleanIcon(htmlData))
    },
  },
  onLoadError: (status, jqXHR) => alert(`${status}: ${jqXHR.responseText}`),
}

export function initBootstrapTableStimulus(table, options) {
  const locale = I18n.locale === 'de' ? 'de-DE' : 'en-US'
  options.exportOptions = Object.assign({}, options.exportOptions, defaultOptions.exportOptions)
  const bootstrapOptions = Object.assign({ locale }, defaultOptions, options)

  window.jQuery(table).bootstrapTable(bootstrapOptions)
}

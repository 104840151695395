import { Controller } from 'stimulus'
import { DateTime } from '../src/javascripts/luxon_date_time'
import Chart from 'chart.js'
import 'chartjs-adapter-luxon'

export default class extends Controller {
  static targets = ['canvas']

  connect() {
    this.element.controller = this
  }

  get tableController() {
    return document.getElementById('statistic-tables-controller').controller
  }

  updateChart(data, params) {
    this.resetChart()
    this.setVariables(params)

    this.statisticChart = this.createChart(data)

    this.tableController.parseStatistic()
  }

  setVariables(params) {
    this.chartType = params.match(/(statistic_filter_preset\[chart_type\]=)(.{1,4})&/)[2]
    this.interval = params.match(/(statistic_filter_preset\[interval\]=)(.{1,10})&/)[2]
    this.countMethod = params.match(/(statistic_filter_preset\[count_method\]=)(.{1,10})&/)[2]
    this.format = this.timeAxe().time.displayFormats[this.interval]
  }

  createChart(data) {
    return new Chart(this.canvasTarget, {
      type: this.chartType,
      options: this.chartOptions(),
      data: {
        datasets: data.datasets.map((dataset) => {
          return {
            label: dataset.label,
            data: this.formatPoints(dataset.data),
            lineTension: 0.2,
          }
        }),
      },
    })
  }

  formatPoints(data, index) {
    const values = []
    const countMethod = this.countMethod

    data.forEach((point) => {
      values.push({
        x: new Date(point.x).toISOString(),
        y: point[countMethod],
      })
    })

    return values
  }

  chartOptions() {
    return {
      maintainAspectRatio: true,
      aspectRatio: 2,
      legend: {
        position: 'bottom',
        align: 'left',
        fullWidth: true,
      },
      animation: false,
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => {
            return this.tooltipTitle(tooltipItem)
          },
          label: (tooltipItem, data) => {
            return this.tooltipLabel(tooltipItem, data)
          },
        },
      },
      scales: {
        yAxes: [this.valueAxe()],
        xAxes: [this.timeAxe()],
      },
    }
  }

  resetChart() {
    if (typeof this.statisticChart !== 'undefined') {
      return this.statisticChart.destroy()
    }
  }

  tooltipTitle(tooltipItem) {
    return DateTime.fromISO(new Date(tooltipItem[0].xLabel).toISOString()).toFormat(this.format)
  }

  tooltipLabel(tooltipItem, data) {
    const graph = data.datasets[tooltipItem.datasetIndex].label
    const formattedValue = this.formatValue(tooltipItem.value)
    return graph + ': ' + formattedValue
  }

  valueAxe() {
    return {
      ticks: {
        beginAtZero: true,
        min: 0,
        callback: (value, index, values) => {
          return this.formatValue(value)
        },
      },
    }
  }

  timeAxe() {
    return {
      type: 'time',
      distribution: 'linear',
      offset: this.chartType === 'bar',
      bounds: 'data',
      ticks: {
        source: 'auto',
      },
      time: {
        round: 'day',
        unit: this.interval,
        displayFormats: {
          day: 'dd.MM.yyyy',
          week: '(WW) - yyyy',
          month: 'MM/yyyy',
          quarter: 'Qq - yyyy',
          year: 'yyyy',
        },
      },
    }
  }

  removeGraph(label) {
    const index = this.statisticChart.data.datasets.findIndex((dataset) => {
      return dataset.label === label
    })
    if (index > -1) {
      this.statisticChart.data.datasets.splice(index, 1)
    } else {
      return false
    }
    this.statisticChart.update()
    this.tableController.parseStatistic()
  }

  formatValue(value) {
    switch (this.countMethod) {
      case 'value':
        return `${this.decimalFormatter.format(value)} €`
      case 'average':
        return `Ø ${value}`
      default:
        return value
    }
  }

  get decimalFormatter() {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
}

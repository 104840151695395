import { Controller } from 'stimulus'
import { csrfHeader } from '../../src/javascripts/csrf_header'
import $ from 'jquery'

export default class extends Controller {
  connect() {}

  onSelectAccommodationProvider(params) {
    const accProviderValue = params.target.selectedOptions[0].value
    let headers = csrfHeader()
    headers = { 'Content-Type': 'application/json', Accept: 'application/json', ...headers }
    if (accProviderValue !== '') {
      const body = {
        issuing: {
          acc_provider: accProviderValue,
        },
      }
      fetch('/manual_registrations/issuings/get_accommodations.json', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            const isJson = response.headers.get('content-type')?.includes('application/json')
            if (isJson) {
              return response.json()
            }
            return false
          } else throw response.json()
        })
        .then((data) => {
          //Recreate the options of the select
          if (data) {
            const resultAccommodations = data.result
            if (resultAccommodations.length > 0) {
              $('#accommodation_id option:first-child').nextAll().remove()
              const newOptions = []
              resultAccommodations.forEach((item) => {
                newOptions.push(`<option value="${item.uid}">${item.name}</option>`)
              })
              $('#accommodation_id option:first-child').after(newOptions)
            }
          }
        })
        .catch((error) => {
          alert(error)
        })
    } else {
      $('#accommodation_id option:first-child').nextAll().remove()
    }
  }

  calculateUntil(params) {
    const fromValue = $('#registration_number_from').val()
    const amount = $('#amount').val()
    const untilValue = (parseInt(fromValue) || 0) + (parseInt(amount) - 1 || 0)
    $('#registration_number_until').val(untilValue)
  }
}

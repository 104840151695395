import { Controller } from 'stimulus'
import { initSelect2 } from '../src/javascripts/select2'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import { initDatepicker } from '../src/javascripts/datepicker'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['modal']

  connect() {
    initI18n()
    this.fetchModalTemplate()
  }

  renderModal(event, type) {
    const modal = $(this.modalTemplate)
    const url = this.findUrlByElement(event.target)
    if (url === undefined) return this.throwUrlError()

    $(modal).modal()
    this.fetchModalContent(url, modal[0])
  }

  renderModalWithDatePicker(event, type) {
    const modal = $(this.modalTemplate)
    const url = this.findUrlByElement(event.target)
    if (url === undefined) return this.throwUrlError()

    $(modal).modal()
    this.fetchModalContent(url, modal[0], true)
  }

  fetchModalContent(url, modal, useDatePicker = false) {
    const modalContent = modal.querySelector('.modal-content')
    const modalBody = modal.querySelector('.modal-body')
    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        modalBody.innerHTML = html
        initSelect2(modalContent)
        if (useDatePicker) {
          initDatepicker()
        }
      })
  }

  displayErrors(event) {
    // NOTE: to display errors correctly in modals
    // 1. the backend controller should respond with a not 2XX status and render errors: { ... }
    // 2. if you want to automatically render a link to the edit form for updating the object
    //   a. the error response should also include link: "..."
    //   b. the .modal-body should include a #editLinkPlaceholder element
    const errorResponse = event.detail[0]
    const errors = errorResponse.errors
    const link = errorResponse.link
    const editLinkPlaceholder = this.modalTarget.querySelector('.modal-body #editLinkPlaceholder')

    if (link && editLinkPlaceholder) this.renderErrorsAndEditLink(errors, link, editLinkPlaceholder)
    else alert(errors)
  }

  renderErrorsAndEditLink(errors, link, placeholderElement) {
    placeholderElement.innerHTML = `
      <p class='text-danger'>
        ${errors}
        <a href='${link}' class='btn btn-sm btn-outline-secondary'>${I18n.t('edit')}</a>
      </p>`
  }

  refreshContent(event) {
    const table = this.searchTable(event.target)

    if (table) this.refreshTable(table)
    else location.reload()
    $(this.modalTarget).modal('hide')
  }

  refreshTable(table) {
    $(table).bootstrapTable('refresh')
  }

  download(event) {
    // Will redirect to the returned link and close the modal.
    // NOTE: When downloading on a submit, the response should not redirect but pass the
    // link: "..." attribute and a 200 status code. Errors should be passed as described above.
    const [response, _status, request] = event.detail
    const { link } = response
    if (request.status == 200 && link) {
      location.href = link
      $(this.modalTarget).modal('hide')
    } else {
      this.displayErrors(event)
    }
  }

  searchTable(element) {
    const tableSelector = element.dataset.table
    return document.querySelector(`[data-target="${tableSelector}"]`)
  }

  fetchModalTemplate() {
    fetch('/modal')
      .then((response) => response.text())
      .then((html) => {
        this.modalTemplate = html
      })
  }

  findUrlByElement(element) {
    // in case of nested elements like icons
    return element.dataset.url || element.parentElement.dataset.url
  }

  throwUrlError() {
    console.error(
      'no url found in dataset of event target; please make sure to trigger this event from the correct target'
    )
  }
}

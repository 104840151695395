import { Controller } from 'stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'

export default class extends Controller {
  connect() {
    initI18n()
    this.renderWidget()
  }

  renderWidget() {
    const element = document.querySelector(`#${this.element.id}`)
    if (element && element instanceof HTMLElement) {
      window.WELCMpass.Booking.render(`#${this.element.id}`, {
        apiKey: this.apiKey,
        communeId: this.communeId,
        offlinePayment: this.offlinePayment,
        language: I18n.locale,
      })
    }
  }

  get apiKey() {
    return this.element.dataset.apiKey
  }

  get communeId() {
    return this.element.dataset.communeId
  }

  get offlinePayment() {
    return this.element.dataset.offlinePayment === '1'
  }
}

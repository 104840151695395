// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'popper.js'
import 'bootstrap'
import 'chart.js'
import 'chartjs-plugin-colorschemes'
import 'table2csv'

import '../src/javascripts/localization'
import '../src/javascripts/flash'
import 'bootstrap'
import '../src/javascripts/bootstrap_table_stimulus'
import '../src/javascripts/tinyMce'
import '../src/javascripts/datepicker'

import 'controllers'

import { initSelect2 } from '../src/javascripts/select2'
import { testSession } from '../src/javascripts/session_listener.js'

document.addEventListener('turbolinks:load', (event) => {
  initSelect2()
  testSession()
})

require('@rails/ujs').start()
require('turbolinks').start()
require('channels')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true, /\.(?:png|jpg|gif|ico|svg|webp)$/)
// const imagePath = (name) => images(name, true)

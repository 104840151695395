import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import { booleanIcon } from '../src/javascripts/boolean_icon'

export default class extends Controller {
  static targets = ['table', 'form', 'loadingIndicator']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.accommodation_provider' }),
      },
      columns: [
        {
          field: 'name',
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'accommodation_identifier',
          formatter: this.accommodationidentifierFormatter,
          title: I18n.t('accommodation_identifier', {
            scope: 'activerecord.attributes.accommodation_provider',
          }),
        },
        {
          field: 'street',
          sortable: true,
          title: I18n.t('street', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'zip',
          sortable: true,
          title: I18n.t('zip', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'city',
          sortable: true,
          title: I18n.t('city', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'telephone',
          sortable: true,
          title: I18n.t('telephone', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'email',
          sortable: true,
          title: I18n.t('email', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'residence_type',
          title: I18n.t('residence_type', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'employee_area',
          title: I18n.t('employee_area', {
            scope: 'activerecord.attributes.accommodation_provider',
          }),
        },
        {
          field: 'active',
          formatter: booleanIcon,
          title: I18n.t('active', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/accommodation_providers/${row.uid}' class='text-secondary'>${row.name}</a>`
    )
  }

  accommodationidentifierFormatter(value, row) {
    const displayValue = value.split('-').pop()

    return displayValue
  }

  filterAccommodationProviders() {
    const limit = '&limit=' + document.querySelector('.page-size').innerText
    const params = $(this.formTarget).serialize() + limit
    this.loadingIndicatorTarget.classList.remove('d-none')
    fetch('/accommodation_providers.json?' + params)
      .then((response) => {
        this.loadingIndicatorTarget.classList.add('d-none')
        if (response.ok) return response.json()
        else throw response.json()
      })
      .then((data) => {
        window.jQuery(this.tableTarget).bootstrapTable('load', data)
      })
      .catch((error) => {
        Promise.resolve(error).then((message) => alert(message.error))
      })
  }
}

import { Controller } from 'stimulus'
import { csrfHeader } from '../src/javascripts/csrf_header'
import { initSelect2 } from '../src/javascripts/select2'
import { initDatepicker } from '../src/javascripts/datepicker'
import $ from 'jquery'

const FIRST_PERSON_INDEX = '123456789'
export default class extends Controller {
  static targets = ['form', 'fields', 'anonymousFields', 'addItem']

  connect() {
    $(`#personal_information_fields_${FIRST_PERSON_INDEX}`).collapse('show')
    if (this.hasAnonymousFieldsTarget) this.toggleAnonymousFields()

    const autofillRequestBtn = document.querySelector('.btn.btn-pre-success.btn-autofill-and-request');
    if (autofillRequestBtn) {
      autofillRequestBtn.addEventListener('click', this.onAutofillRequestBtn);
    }
  }

  addTaxcard(event) {
    event.preventDefault()
    const identifier = new Date().valueOf()
    $('[id^="personal_information_fields_"]').collapse('hide')
    this.applyFirstPersonValues()
    // Get accommodation original value
    const originalAccommodationSelect = $('[name="transaction[taxcards_attributes][123456789][stay_accommodation_identification_nr]"]').first();
    console.log("Original Accommodation value", originalAccommodationSelect.val());
    const fieldsFragement = this.fieldsTarget.innerHTML.replace(/123456789/g, identifier)
    this.addItemTarget.insertAdjacentHTML('beforebegin', fieldsFragement)
    initSelect2()
    initDatepicker()

    //Set accommodation original value into new accommodation field
    const newAccommodationSelect = $(`[name="transaction[taxcards_attributes][${identifier}][stay_accommodation_identification_nr]"]`).first();
    newAccommodationSelect.val(originalAccommodationSelect.val());
    newAccommodationSelect.trigger('change');

    $(`#personal_information_fields_${identifier}`).collapse('show')
    this.formTarget
      .querySelector(`[name="transaction[taxcards_attributes][${identifier}][stay_begin]"]`)
      .focus()
  }

  applyFirstPersonValues() {
    this.fieldsTarget.querySelectorAll('input').forEach((element) => {
      if (
        element.name.includes('person_salutation') ||
        element.name.includes('person_firstname') ||
        element.name.includes('person_lastname') ||
        element.name.includes('person_birthday') ||
        element.name.includes('special_condition')
      )
        return

      element.setAttribute('value', element.value)
    })
  }

  removeTaxcard(event) {
    event.preventDefault()

    const id = event.target.parentElement.dataset.targetId
    if (id == FIRST_PERSON_INDEX) alert('Erste Person kann nicht entfernt werden!')
    else this.formTarget.querySelector(`#personal_information_card_${id}`).remove()
  }

  toggleAnonymousFields(_event) {
    const anonymous = document.querySelector(
      `#transaction_taxcards_attributes_${FIRST_PERSON_INDEX}_is_anonymous`
    ).checked

    this.anonymousFieldsTarget.querySelectorAll('input').forEach((element) => {
      element.disabled = !anonymous
    })
    this.fieldsTarget.querySelectorAll('input').forEach((element) => {
      element.disabled = anonymous
    })
  }

  onAutofillRequestBtn(event) {
    //Auto Fill all required fields
    //Birthdays
    const birthdayFields = document.querySelectorAll("input[id^='transaction_taxcards_attributes_'][id$='_person_birthday']");
    birthdayFields.forEach(birthdayField => {
      if (birthdayField.value.trim().length === 0) {
        birthdayField._flatpickr.setDate(new Date('2000-01-01'));
      }
    });

    //Streets
    const streetFields = document.querySelectorAll("input[id^='transaction_taxcards_attributes_'][id$='_person_street']");
    streetFields.forEach(streetField => {
      if (streetField.value.trim().length === 0) {
        streetField.value = "bitte eintragen";
      }
    });

    //ZipCodes
    const zipFields = document.querySelectorAll("input[id^='transaction_taxcards_attributes_'][id$='_person_zip']");
    zipFields.forEach(zipField => {
      if (zipField.value.trim().length === 0) {
        zipField.value = "00000";
      }
    });

    //Cities
    const cityFields = document.querySelectorAll("input[id^='transaction_taxcards_attributes_'][id$='_person_residence']");
    cityFields.forEach(cityField => {
      if (cityField.value.trim().length === 0) {
        cityField.value = "bitte eintragen";
      }
    });

    // Click the submit button with the correct data-action
    const requestPriceModalBtn = document.querySelector('[data-action="transaction-booking#requestPriceModal"]');
    requestPriceModalBtn.click();
  }

  requestPriceModal(event) {
    event.preventDefault()

    const formData = new FormData(this.formTarget)

    fetch('/transactions_bookings/booking_overview', {
      method: 'PUT',
      headers: csrfHeader(),
      body: formData,
    })
      .then((response) => {
        if (response.ok) return response.text()
        else throw response.json()
      })
      .then((bookingOverview) => {
        $(bookingOverview).modal()
      })
      .catch((error) => {
        Promise.resolve(error).then((message) => alert(message.error))
      })
  }

  submitForm(event) {
    event.preventDefault()
    event.currentTarget.style.visibility = 'hidden'
    // formTarget not available as this is on another controller entity
    const formData = new FormData(document.forms['transaction-form'])
    fetch('/transactions_bookings', {
      method: 'POST',
      headers: csrfHeader(),
      body: formData,
    })
      .then((response) => {
        if (response.ok) return response.json()
        else throw response.json()
      })
      .then((data) => {
        document.querySelector('.modal.show #initial-actions-row').style.display = 'none'
        const furtherActionsRow = document.querySelector('.modal.show #further-actions-row')
        furtherActionsRow.style.display = 'block'
        furtherActionsRow.dataset.taxcardsLink = `/transactions_bookings/print`
        furtherActionsRow.dataset.taxcardUID = data.taxcardUID
        if (data.registrationFormLink) {
          furtherActionsRow.dataset.registrationFormLink =
            location.origin + data.registrationFormLink
        } else {
          document.querySelector('.modal.show .download-registration-form-btn').style.display =
            'none'
        }
        // Set the default value of the checkbox for the background
        const printRequestBackgroundCheckbox = furtherActionsRow.querySelector('#print_request_background')
        printRequestBackgroundCheckbox.checked = data.backgroundCheckboxDefault
      })
      .catch((error) => {
        Promise.resolve(error).then((message) => alert(message.error))
      })
  }

  downloadTaxcards(event) {
    event.preventDefault()
    const furtherActionsRow = document.getElementById('further-actions-row')
    const printRequestBackgroundField = furtherActionsRow.querySelector('#print_request_background')
    const body = {
      print_request: {
        background: printRequestBackgroundField.checked ? 1 : 0,
        taxcard_id: furtherActionsRow.dataset.taxcardUID,
      },
    }
    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(furtherActionsRow.dataset.taxcardsLink, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) return response.json()
          alert("Error downloading/herunterladen");
      })
      .then((responseJSON) => {
        window.open(responseJSON.link, '_blank')
      })
  }

  downloadRegistrationForm(event) {
    event.preventDefault()
    const furtherActionsRow = document.getElementById('further-actions-row')
    window.open(furtherActionsRow.dataset.registrationFormLink, '_blank')
  }

  closeModal(event) {
    event.preventDefault()
    location.reload()
  }
}

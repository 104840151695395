import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.welcmpass_info' }),
      },
      columns: [
        {
          field: 'uid',
          sortable: true,
          formatter: this.nameFormatter,
          title: I18n.t('uid', { scope: 'activerecord.attributes.welcmpass_infos' }),
        },
        {
          field: 'owner_id',
          sortable: true,
          title: I18n.t('owner_id', { scope: 'activerecord.attributes.welcmpass_infos' }),
        },        
        {
          field: 'owner_type',
          sortable: true,
          title: I18n.t('owner_type', { scope: 'activerecord.attributes.welcmpass_infos' }),
        },              
        {
          field: 'content_text',
          sortable: true,
          title: I18n.t('content_text', { scope: 'activerecord.attributes.welcmpass_infos' }),
        },
        {
          field: 'content_image',
          sortable: true,
          formatter: this.imageFormatter,
          title: I18n.t('content_image', { scope: 'activerecord.attributes.welcmpass_infos' }),
        }
      ],
    }
  }

  /* This Formatter could be used in the future

  accommodationProviderFormatter (value, row) {
    return DOMPurify.sanitize(`
      <a class='btn btn-sm btn-outline-secondary float-right' data-action='modals#renderModal' data-url='/admin/accommodations/${row.uid}/accommodation_modals/agency'><i class='fal fa-exchange'></i></a>
      ${value}
    `)
  } */

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/welcmpass_infos/${row.uid}' class='text-secondary'>Info ID: ${row.uid}</a>`
    )
  }

  imageFormatter(value, row) {
    console.log("Image name", value);
    return value.url
  }
}

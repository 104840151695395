import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { serialize } from '../../src/javascripts/serialize'
import $ from 'jquery'
import { moneyFormatter } from '../../src/javascripts/money_formatter'
import { dateFormatter } from '../../src/javascripts/date_formatter'
import moment from 'moment'

export default class extends Controller {
  static targets = ['table', 'form']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      queryParams: (params) => this.mergeFormParams(params, this.formParams),
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.taxcard' }),
        ignoreColumn: 'actions',
      },
      columns: [
        {
          field: 'commune.titleofcommune',
          title: I18n.t('one', { scope: 'activerecord.models.commune' }),
        },
        {
          field: 'ticket_card_number',
          title: I18n.t('ticket_card_number', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'person_name',
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'create_date',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('create_date', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'stay_guesttype',
          sortable: true,
          formatter: this.guestTypeFormatter,
          title: I18n.t('stay_guesttype', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'stay_begin',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('stay_begin', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'stay_end',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('stay_end', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'person_pets',
          sortable: true,
          title: I18n.t('person_pets', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'pricedefinition_special_condition',
          formatter: (value, row) => this.specialConditionFormatter(row, this),
          title: I18n.t('one', { scope: 'activerecord.models.special_condition' }),
        },
        {
          field: 'discount',
          sortable: false,
          formatter: (value, row) => this.discountFormatter(row, this),
          title: I18n.t('discount', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'price',
          sortable: true,
          formatter: moneyFormatter,
          title: I18n.t('price', { scope: 'activerecord.attributes.transaction' }),
        },
        {
          field: 'stay_accommodation_identification_nr',
          sortable: false,
          title: I18n.t('one', { scope: 'activerecord.models.accommodation' }),
        },
        {
          field: 'status',
          sortable: true,
          formatter: this.statusFormatter,
          title: I18n.t('status', { scope: 'activerecord.attributes.taxcard' }),
        },
        {
          field: 'actions',
          sortable: false,
          formatter: (value, row) => this.actionsFormatter(this, row),
        },
      ],
    }
  }

  cardNumberFormatter(value, row) {
    return `${row.ticket_card_number}`
  }

  statusFormatter(value, row) {
    return I18n.t(value, { scope: 'activerecord.attributes.taxcard.states' })
  }

  guestTypeFormatter(value, row) {
    return I18n.t(value, { scope: 'activerecord.attributes.taxcard.stay_guesttypes' })
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/taxcards/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  specialConditionFormatter(row, controller) {
    const specialCondition = controller.specialConditionFromPriceProjections(row)
    if (specialCondition) return specialCondition.title
  }

  discountFormatter(row, controller) {
    const specialCondition = controller.specialConditionFromPriceProjections(row)
    if (specialCondition) return `${specialCondition.discount}%`
  }

  specialConditionFromPriceProjections(row) {
    return row.price_discount_projection
  }

  actionsFormatter(controller, row) {
    const registrationFormButton = controller.registrationFormButton(row)
    const qrcodeButton = controller.qrcodeButton(row)

    return `<div class="btn-group">${qrcodeButton}${registrationFormButton}</div>`
  }

  registrationFormButton(row) {
    if (row.stay_guesttype !== 'overnight' || row.registration_form === null) return ''

    const url = `/registration_forms/${row.registration_form}/download`
    const title = I18n.t('print_registration_form', { scope: 'taxcards' })

    return `
      <a class="btn btn-sm btn-outline-secondary" title='${title}' target="_blank" rel="noopener" href="${url}">
        <i class="fal fa-print"></i>
      </a>
    `
  }

  qrcodeButton(row) {
    const title = I18n.t('print_taxcard', { scope: 'taxcards' })

    return `<a
      title='${title}'
      class="btn btn-sm btn-secondary"
      data-action="modals#renderModal"
      data-url="/taxcards/${row.uid}/taxcard_modals/print_request"
    >
      <i class="fal fa-qrcode"></i>
    </a>`
  }

  setStayBeginWithinThreeDays(evt) {
    const target = evt.target || evt.currentTarget
    if (!target) {
      return
    }
    const isChecked = target.checked
    if (isChecked) {
      const now = moment().format('DD.MM.YYYY')
      const inThreeDays = moment().add(3, 'days').format('DD.MM.YYYY')
      document.querySelector('#stay_begin_from').value = now
      document.querySelector('#stay_begin_to').value = inThreeDays
    } else {
      document.querySelector('#stay_begin_from').value = ''
      document.querySelector('#stay_begin_to').value = ''
    }
    this.filterTaxcards()
  }

  setAllBuyYears(evt) {
    let isChecked = false
    if (evt) {
      const target = evt.target || evt.currentTarget
      if (!target) {
        return
      }
      isChecked = target.checked
    } else {
      isChecked = !!document.querySelector('[name=scope6]:checked')
    }
    if (isChecked) {
      $('#transaction_buy_year').attr('disabled', 'disabled')
    } else {
      $('#transaction_buy_year').removeAttr('disabled')
    }
    if (evt) {
      this.filterTaxcards()
    }
  }

  mergeFormParams(params) {
    this.setAllBuyYears()
    params.stay_begin_from = document.querySelector('#stay_begin_from').value
    params.stay_begin_to = document.querySelector('#stay_begin_to').value
    params.scope1 = document.querySelector('[name=scope1]:checked').value
    params.scope2 = document.querySelector('[name=scope2]:checked').value
    params.scope3 = document.querySelector('[name=scope3]:checked').value
    params.scope4 = document.querySelector('[name=scope4]:checked').value
    const scope5 = document.querySelector('[name=scope5]:checked')
    if (scope5) {
      params.scope5 = scope5.value
    }
    const scope6 = document.querySelector('[name=scope6]:checked')
    if (scope6) {
      params.scope6 = scope6.value
    }
    params.transaction_buy_year = document.querySelector('[name=transaction_buy_year]').value
    return params
  }

  filterTaxcards() {
    const pageLimit = '&limit=' + document.querySelector('.page-size').innerText
    const searchInput = '&search=' + document.querySelector('.search-input').value
    const params = serialize(this.formTarget) + pageLimit + searchInput

    $(this.tableTarget).bootstrapTable('showLoading')

    fetch(`${this.tableTarget.dataset.url}?${params}`)
      .then((response) => response.json())
      .then((data) => {
        $(this.tableTarget).bootstrapTable('load', data)
        $(this.tableTarget).bootstrapTable('hideLoading')
      })
  }
}

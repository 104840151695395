import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { booleanIcon } from '../../src/javascripts/boolean_icon'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.special_condition' }),
      },
      columns: [
        {
          field: 'titleofcommune',
          title: I18n.t('one', { scope: 'activerecord.models.commune' }),
        },
        {
          field: 'title',
          sortable: true,
          formatter: this.titleFormatter,
          title: I18n.t('title', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'description',
          sortable: true,
          formatter: this.descriptionFormatter,
          title: I18n.t('description', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'special_condition_category.name',
          title: I18n.t('special_condition_category', {
            scope: 'activerecord.attributes.special_condition',
          }),
        },
        {
          field: 'shorthand',
          sortable: true,
          title: I18n.t('shorthand', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'age_range',
          title: I18n.t('age_range', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'discount',
          sortable: true,
          formatter: this.discountFormatter,
          title: I18n.t('discount', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'disabilitygrade',
          sortable: true,
          title: I18n.t('disabilitygrade', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'clearance_needed',
          sortable: true,
          formatter: booleanIcon,
          title: 'FnP',
        },
        {
          field: 'document_mandatory',
          formatter: booleanIcon,
          title: I18n.t('document_mandatory', {
            scope: 'activerecord.attributes.special_condition',
          }),
        },
        {
          field: 'id_mandatory',
          formatter: booleanIcon,
          title: I18n.t('id_mandatory', { scope: 'activerecord.attributes.special_condition' }),
        },
        {
          field: 'photo_mandatory',
          formatter: booleanIcon,
          title: I18n.t('photo_mandatory', { scope: 'activerecord.attributes.special_condition' }),
        },
      ],
    }
  }

  titleFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/special_conditions/${row.uid}' class='text-secondary'>${row.title}</a>`
    )
  }

  descriptionFormatter(value, row) {
    const description =
      row.description.length > 20 ? row.description.substring(0, 17) + '...' : row.description
    return DOMPurify.sanitize(description)
  }

  discountFormatter(value, row) {
    return `${value} %`
  }
}

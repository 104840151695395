import DOMPurify from 'dompurify'
import { Controller } from 'stimulus'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import { serialize } from '../src/javascripts/serialize'
import { booleanIcon } from '../src/javascripts/boolean_icon'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table', 'form']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      search: false,
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.booking_mandatory_field' }),
      },
      columns: [
        {
          field: 'fieldname',
          sortable: true,
          formatter: this.nameFormatter,
          title: I18n.t('fieldname', { scope: 'activerecord.attributes.booking_mandatory_field' }),
        },
        {
          field: 'guest_type',
          sortable: true,
          formatter: this.guestTypeFormatter,
          title: I18n.t('guest_type', {
            scope: 'activerecord.attributes.booking_mandatory_field',
          }),
        },
        {
          field: 'mandatory',
          sortable: true,
          formatter: booleanIcon,
          title: I18n.t('mandatory', {
            scope: 'activerecord.attributes.booking_mandatory_field',
          }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    const name = I18n.t(value, {
      scope: 'activerecord.attributes.booking_mandatory_field.fieldnames',
    })
    return DOMPurify.sanitize(
      `<a href='booking_mandatory_fields/${row.uid}/edit' class='text-secondary'>${name}</a>`
    )
  }

  guestTypeFormatter(value) {
    return I18n.t(value, {
      scope: 'activerecord.attributes.booking_mandatory_field.guest_types',
    })
  }
}

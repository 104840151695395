import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.accommodation_provider' }),
      },
      columns: [
        {
          field: 'name',
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'street',
          sortable: true,
          title: I18n.t('street', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'zip',
          sortable: true,
          title: I18n.t('zip', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'city',
          sortable: true,
          title: I18n.t('city', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'telephone',
          sortable: true,
          title: I18n.t('telephone', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'email',
          sortable: true,
          title: I18n.t('email', { scope: 'activerecord.attributes.accommodation_provider' }),
        },
        {
          field: 'residence_type',
          title: I18n.t('residence_type', { scope: 'activerecord.attributes.annual_card_user' }),
        },
        {
          field: 'employee_area',
          title: I18n.t('employee_area', {
            scope: 'activerecord.attributes.accommodation_provider',
          }),
        },
      ],
    }
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/accommodation/accommodation_providers/${row.uid}' class='text-secondary'>${row.name}</a>`
    )
  }
}

import { Controller } from 'stimulus'
import { initSelect2 } from '../src/javascripts/select2'
import { csrfHeader } from '../src/javascripts/csrf_header'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['form', 'spinner', 'extraGraphSection']

  connect() {
    // makes controller selectable through query selectors
    this.element.controller = this
    this.bindEvents()
    this.applyStandardFilter()
    this.highlightSectionLabel(this)
    this.getStatisticResults()
  }

  bindEvents() {
    this.formTarget.addEventListener('change', (event) => {
      this.applyStandardFilter(event.target)
      this.highlightSectionLabel(this)
    })

    document.addEventListener('turbolinks:load', (_event) => {
      this.disableSelect2ChangeEvents()
    })

    this.extraGraphSectionTargets.forEach((element) => {
      $(element.nextElementSibling).on('show.bs.collapse', (event) =>
        this.resetOtherExtraGraphSection(event, this)
      )
    })
  }

  toggleDashboard(event) {
    const presetId = event.target.dataset.id
    if (!presetId) return

    fetch(`/statistics/${presetId}/toggle_dashboard.json`, {
      method: 'PATCH',
      headers: csrfHeader(),
    }).then((response) => {
      if (response.ok) return

      event.target.checked = false
      response.json().then((data) => alert(data.message))
    })
  }

  disableSelect2ChangeEvents() {
    initSelect2()

    const select2Search = document.querySelectorAll('.select2-search__field')
    select2Search.forEach((element) => {
      element.addEventListener('change', (event) => event.stopPropagation())
    })
  }

  applyStandardFilter(target = null) {
    const standardFilter = document.querySelector(
      '[name="statistic_filter_preset[standard_filter]"]:checked'
    )
    if (!target || target.name === 'statistic_filter_preset[standard_filter]') {
      const [countMethod, reference, date] = standardFilter.value.split('#')
      document.getElementById(`statistic_filter_preset_count_method_${countMethod}`).checked = true
      document.getElementById(`statistic_filter_preset_reference_${reference}`).checked = true
      if (date !== 'no_date') {
        document.getElementById(`statistic_filter_preset_date_${date}`).checked = true
      }
    } else {
      const countMethod = document.querySelector(
        '[name="statistic_filter_preset[count_method]"]:checked'
      ).value
      const reference = document.querySelector(
        '[name="statistic_filter_preset[reference]"]:checked'
      ).value
      const selector = `statistic_filter_preset_standard_filter_${countMethod}${reference}no_date`
      if (document.getElementById(selector)) document.getElementById(selector).checked = true
      else if (standardFilter) standardFilter.checked = false
    }
  }

  resetOtherExtraGraphSection(event, controller) {
    const additionalAllowedSections = 1
    let selectedSections = 0
    const otherSections = controller.formTarget.querySelectorAll(
      `.statistic-selection-group:not([id=${event.target.id}])`
    )
    otherSections.forEach((section) => {
      selectedSections < additionalAllowedSections &&
      controller.anySelectedOptions(section.previousElementSibling)
        ? ++selectedSections
        : controller.resetExtraGraphSection(section)
    })
    controller.highlightSectionLabel(controller)
  }

  resetExtraGraphSection(section) {
    const selectField = section.querySelector('.select2-hidden-accessible')
    if (selectField) {
      selectField.value = null
      selectField.dispatchEvent(new Event('change'))
    } else {
      section.querySelectorAll('input').forEach((element) => {
        element.checked = false
      })
    }
  }

  highlightSectionLabel(controller) {
    controller.extraGraphSectionTargets.forEach((element) => {
      if (controller.anySelectedOptions(element)) {
        element.classList.add('font-weight-normal')
      } else {
        element.classList.remove('font-weight-normal')
      }
    })
  }

  anySelectedOptions(element) {
    const toggleElement = element.querySelector('.statistic-group-toggle')
    const selectorInputs = element.parentElement.querySelector(toggleElement.hash)
    if (selectorInputs === null) return 0
    else return selectorInputs.querySelectorAll(':checked').length
  }

  get chartController() {
    return document.getElementById('statistic-charts-controller').controller
  }

  addFilterPreset(event) {
    const name = prompt(I18n.t('enter_preset_name', { scope: 'statistics' }))

    if (name) {
      document.getElementById('statistic_filter_preset_name').value = name
      return this.formTarget.submit()
    }
  }

  toggleAll(event) {
    const filterId = event.target.parentElement.parentElement.id

    document.querySelectorAll(`#${filterId} input`).forEach((element) => {
      element.checked = event.target.checked
    })
  }

  prepareChartUpdate(controller, event) {
    if (event.target.type === 'checkbox' && !event.target.checked) {
      const label = event.target.labels[0].textContent
      controller.chartController.removeGraph(label)
    } else {
      controller.spinnerTarget.classList.remove('d-none')
      controller.getStatisticResults()
    }
  }

  getStatisticResults() {
    this.spinnerTarget.classList.remove('d-none')
    const params = $(this.formTarget).serialize()
    fetch('/statistics.json?' + params, {
      dataType: 'json',
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/user_communes/sign_in'
        } else {
          this.spinnerTarget.classList.add('d-none')
          return response.json()
        }
      })
      .then((data) => this.chartController.updateChart(data, unescape(params)))
  }

  // NOTE: currently used for table export but could also be used for chart title
  get filterSummary() {
    const dateStart = this.formTarget.querySelector('#statistic_filter_preset_date_start').value
    const dateEnd = this.formTarget.querySelector('#statistic_filter_preset_date_end').value
    const interval = this.formTarget.querySelector(
      '#statistic_filter_preset_interval :checked'
    ).innerText
    const filter = this.formTarget.querySelector(
      '[name="statistic_filter_preset[standard_filter]"]:checked'
    ).nextElementSibling.innerText
    return `${dateStart} – ${dateEnd} ${filter} pro ${interval}`
  }
}

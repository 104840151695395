import { Controller } from 'stimulus'
import { DateTime } from '../src/javascripts/luxon_date_time'
import Chart from 'chart.js'
import 'chartjs-adapter-luxon'
import { serializeJSON } from '../src/javascripts/serialize'
import { timeFormatter } from '../src/javascripts/time_formatter'

export default class extends Controller {
  static targets = ['canvas', 'timestamp']

  connect() {
    this.refresh()
  }

  refresh() {
    this.canvasTargets.forEach((canvas) => this.updateChart(canvas))
    this.setTimestamp()
  }

  updateChart(canvasElement) {
    this.setVariables(canvasElement)
    this.resetChart(canvasElement)
    this.toggleSpinnger(canvasElement)

    const params = serializeJSON({ statistic_filter_preset: canvasElement.filter })
    fetch('/statistics.json?' + params)
      .then((response) => response.json())
      .then((data) => {
        canvasElement.statisticChart = this.createChart(data, canvasElement)
        this.toggleSpinnger(canvasElement, false)
      })
  }

  setVariables(canvasElement) {
    canvasElement.filter = JSON.parse(canvasElement.dataset.filter)
    canvasElement.chartType = canvasElement.filter.chart_type
    canvasElement.interval = canvasElement.filter.interval
    canvasElement.countMethod = canvasElement.filter.count_method
    canvasElement.format = this.timeAxe(
      canvasElement.chartType,
      canvasElement.interval
    ).time.displayFormats[canvasElement.filter.interval]
  }

  toggleSpinnger(canvasElement, show = true) {
    if (show) {
      canvasElement.previousSibling.classList.remove('d-none')
    } else {
      canvasElement.previousSibling.classList.add('d-none')
    }
  }

  setTimestamp() {
    this.timestampTarget.innerHTML = timeFormatter(new Date())
  }

  createChart(data, canvasElement) {
    return new Chart(canvasElement, {
      type: canvasElement.chartType,
      options: this.chartOptions(canvasElement),
      data: {
        datasets: data.datasets.map((dataset) => {
          return {
            label: dataset.label,
            data: this.formatPoints(dataset.data, canvasElement.countMethod),
            lineTension: 0.2,
          }
        }),
      },
    })
  }

  formatPoints(data, countMethod) {
    const values = []

    data.forEach((point) => {
      values.push({
        x: new Date(point.x).toISOString(),
        y: point[countMethod],
      })
    })

    return values
  }

  chartOptions(canvasElement) {
    return {
      maintainAspectRatio: true,
      aspectRatio: 2,
      legend: {
        position: 'bottom',
        align: 'left',
        fullWidth: true,
      },
      animation: false,
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => {
            return this.tooltipTitle(tooltipItem, canvasElement.format)
          },
          label: (tooltipItem, data) => {
            return this.tooltipLabel(tooltipItem, data, canvasElement.countMethod)
          },
        },
      },
      scales: {
        yAxes: [this.valueAxe(canvasElement.countMethod)],
        xAxes: [this.timeAxe(canvasElement.chartType, canvasElement.interval)],
      },
    }
  }

  resetChart(canvasElement) {
    if (typeof canvasElement.statisticChart !== 'undefined') {
      return canvasElement.statisticChart.destroy()
    }
  }

  tooltipTitle(tooltipItem, format) {
    return DateTime.fromISO(new Date(tooltipItem[0].xLabel).toISOString()).toFormat(format)
  }

  tooltipLabel(tooltipItem, data, countMethod) {
    const graph = data.datasets[tooltipItem.datasetIndex].label
    const formattedValue = this.formatValue(tooltipItem.value, countMethod)
    return graph + ': ' + formattedValue
  }

  valueAxe(countMethod) {
    return {
      ticks: {
        beginAtZero: true,
        min: 0,
        callback: (value, index, values) => {
          return this.formatValue(value, countMethod)
        },
      },
    }
  }

  timeAxe(chartType, interval) {
    return {
      type: 'time',
      distribution: 'linear',
      offset: chartType === 'bar',
      bounds: 'data',
      ticks: {
        source: 'auto',
      },
      time: {
        round: 'day',
        unit: interval,
        displayFormats: {
          day: 'dd.MM.yyyy',
          week: '(WW) - yyyy',
          month: 'MM/yyyy',
          quarter: 'Qq - yyyy',
          year: 'yyyy',
        },
      },
    }
  }

  removeGraph(label) {
    const index = this.statisticChart.data.datasets.findIndex((dataset) => {
      return dataset.label === label
    })
    if (index > -1) {
      this.statisticChart.data.datasets.splice(index, 1)
    } else {
      return false
    }
    this.statisticChart.update()
  }

  formatValue(value, countMethod) {
    switch (countMethod) {
      case 'value':
        return `${this.decimalFormatter.format(value)} €`
      case 'average':
        return `Ø ${value}`
      default:
        return value
    }
  }

  get decimalFormatter() {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { moneyFormatter } from '../../src/javascripts/money_formatter'
import { dateFormatter } from '../../src/javascripts/date_formatter'

export default class extends Controller {
  static targets = ['table']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.price' }),
      },
      columns: [
        {
          field: 'titleofcommune',
          title: I18n.t('one', { scope: 'activerecord.models.commune' }),
        },
        {
          field: 'title',
          sortable: true,
          formatter: this.titleFormatter,
          title: I18n.t('title', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'description',
          sortable: true,
          formatter: this.descriptionFormatter,
          title: I18n.t('description', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'price',
          sortable: true,
          formatter: moneyFormatter,
          title: I18n.t('price', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'price_category.name',
          title: I18n.t('price_category', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'shorthand',
          sortable: true,
          title: I18n.t('shorthand', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'release_date',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('release_date', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'age_range',
          title: I18n.t('age_range', { scope: 'activerecord.attributes.price' }),
        },
        {
          field: 'date_range',
          title: I18n.t('date_range', { scope: 'activerecord.attributes.price' }),
        },
      ],
    }
  }

  titleFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='/admin/prices/${row.uid}' class='text-secondary'>${row.title}</a>`
    )
  }

  descriptionFormatter(value, row) {
    const description =
      row.description.length > 20 ? row.description.substring(0, 17) + '...' : row.description
    return DOMPurify.sanitize(description)
  }
}

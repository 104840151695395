import { Controller } from 'stimulus'

const typesUsingDisabilityGrade = [6, 7]
export default class extends Controller {
  static targets = ['form', 'select']

  connect() {
    this.bindTemplateSelectionEvent()
  }

  bindTemplateSelectionEvent() {
    if (
      this.formTarget.querySelector('#special_condition_pricedefinitions_specialconditions_type_id')
        .value == 6
    ) {
      this.formTarget.querySelector('#special_condition_document_mandatory').checked = true
    }
    this.selectTarget.addEventListener('change', (event) => {
      const id = event.target.value
      if (!id) return

      fetch(`/special_condition_templates/${id}.json`)
        .then((response) => response.json())
        .then((data) => {
          this.formTarget.querySelector('#special_condition_title').value = data.title
          this.formTarget.querySelector('#special_condition_description').value = data.description
          this.formTarget.querySelector('#special_condition_shorthand').value = data.shorthand
          this.formTarget.querySelector(
            '#special_condition_pricedefinitions_specialconditions_type_id'
          ).value = data.pricedefinitions_specialconditions_type_id
          this.formTarget.querySelector('#special_condition_disabilitygrade').readOnly =
            !typesUsingDisabilityGrade.includes(data.pricedefinitions_specialconditions_type_id)
          this.formTarget.querySelector('#special_condition_disabilitygrade').value =
            data.disabilitygrade
          this.formTarget.querySelector('#special_condition_document_mandatory').checked =
            data.pricedefinitions_specialconditions_type_id == 6
        })
    })
  }
}

import DOMPurify from 'dompurify'
import { Controller } from 'stimulus'
import { initBootstrapTableStimulus } from '../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../src/javascripts/i18n_stimulus'
import { serialize } from '../src/javascripts/serialize'
import $ from 'jquery'
import { dateFormatter } from '../src/javascripts/date_formatter'

export default class extends Controller {
  static targets = ['table', 'form']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      search: true,
      queryParams: (params) => this.mergeFormParams(params, this.formParams),
      exportOptions: {
        fileName: I18n.t('other', { scope: 'activerecord.models.api_key' }),
        ignoreColumn: 'actions',
      },
      columns: [
        {
          field: 'name',
          sortable: true,
          formatter: this.nameFormatter,
          title: I18n.t('name', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'api_key',
          sortable: true,
          formatter: this.apiKeyFormatter,
          title: I18n.t('api_key', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'create_date',
          sortable: true,
          formatter: dateFormatter,
          title: I18n.t('create_date', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'create_user',
          sortable: true,
          formatter: this.createUserFormatter,
          title: I18n.t('create_userid', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'origins',
          sortable: true,
          formatter: this.originsFormatter,
          title: I18n.t('origins', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'offline_payment_allowed',
          sortable: true,
          formatter: this.offlinePaymentFormatter,
          title: I18n.t('offline_payment_allowed', { scope: 'activerecord.attributes.widget' }),
        },
        {
          field: 'accommodation.name',
          title: I18n.t('accommodation', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'accommodation_provider.name',
          title: I18n.t('accommodation_provider', { scope: 'activerecord.attributes.api_key' }),
        },
        {
          field: 'action',
          formatter: (value, row) => this.actionsFormatter(this, row),
        },
      ],
    }
  }

  actionsFormatter(controller, row) {
    const pdfButton = controller.pdfButton(row.id)

    return `<div class="btn-group">${pdfButton}</div>`
  }

  pdfButton(printId) {
    if (printId === null) return ''

    const url = `/api_keys/${printId}/widget.pdf`
    const title = I18n.t('print_widget', { scope: 'api_keys' })

    return `<a href="${url}" title='${title} 'target="_blank" class="btn btn-sm btn-secondary"><i class="fal fa-download"></i></a>`
  }

  createUserFormatter(value, row) {
    if (!value) return '-'

    return DOMPurify.sanitize(
      `<a href='/user_communes/${value.uid}' class='text-secondary'>${value.firstname} ${value.lastname}</a>`
    )
  }

  originsFormatter(value, row) {
    if (!value) return '-'

    let links = ''
    const origins = row.origins.split(',')
    origins.slice(0, 2).forEach((origin) => {
      origin = DOMPurify.sanitize(origin)
      links += `<a href='${origin}' class='text-secondary' target='_blank'>${origin} </a>`
    })
    if (origins.length > 2) links += '...'
    return links
  }

  nameFormatter(value, row) {
    return DOMPurify.sanitize(
      `<a href='api_keys/${row.id}/edit' class='text-secondary'>${value || '-'}</a>`
    )
  }

  apiKeyFormatter(value, row) {
    const label = value.slice(0, 16) + '...'

    return DOMPurify.sanitize(
      `<a href='api_keys/${row.id}/edit' class='text-secondary'>${label}</a>`
    )
  }

  offlinePaymentFormatter(value, row) {
    return I18n.t(value, { scope: 'activerecord.attributes.widget.offline_payments' })
  }

  mergeFormParams(params, formParams) {
    params.api_key_filter = document.querySelector('[name=api_key_filter]:checked').value
    return params
  }

  filterApiKeys() {
    const pageLimit = '&limit=' + document.querySelector('.page-size').innerText
    const searchInput = '&search=' + document.querySelector('.search-input').value
    const params = serialize(this.formTarget) + pageLimit + searchInput
    $(this.tableTarget).bootstrapTable('showLoading')
    fetch('/api_keys.json?' + params)
      .then((response) => response.json())
      .then((data) => {
        $(this.tableTarget).bootstrapTable('load', data)
        $(this.tableTarget).bootstrapTable('hideLoading')
      })
  }
}

import { Controller } from 'stimulus'
import DOMPurify from 'dompurify'
import { initBootstrapTableStimulus } from '../../src/javascripts/bootstrap_table_stimulus'
import { initI18n } from '../../src/javascripts/i18n_stimulus'
import { moneyFormatter } from '../../src/javascripts/money_formatter'
import { dateFormatter } from '../../src/javascripts/date_formatter'

import $ from 'jquery'

export default class extends Controller {
  static targets = ['table', 'form', 'loadingIndicator']

  connect() {
    initI18n()
    initBootstrapTableStimulus(this.tableTarget, this.tableOptions)
  }

  get tableOptions() {
    return {
      id: 'table',
      toggle: 'table',
      search: false,
      sortName: 'registration_number',
      sortOrder: 'asc',
      dataField: 'manual_registrations',
      queryParams: this.mergeFormParams,
      exportOptions: {
        fileName: I18n.t('file_name', { scope: 'manual_registration' }),
      },
      columns: [
        {
          field: 'registration_number',
          sortable: true,
          formatter: this.showLink,
          title: I18n.t('registration_number', { scope: 'manual_registration.column_fields' }),
        },
        {
          field: 'status',
          sortable: true,
          formatter: this.statusFormatter,
          title: I18n.t('status', { scope: 'manual_registration.column_fields' }),
        },
        {
          field: 'cancelled',
          sortable: true,
          formatter: this.booleanFormatter,
          title: I18n.t('cancelled', { scope: 'manual_registration.column_fields' }),
        },
        {
          field: 'accommodation_provider_column_name',
          sortable: true,
          title: I18n.t('accommodation_provider_name', {
            scope: 'manual_registration.column_fields',
          }),
        },
        {
          field: 'total_cost',
          sortable: true,
          formatter: this.fullMoneyFormatter,
          title: I18n.t('total_cost', { scope: 'manual_registration.column_fields' }),
        },
        {
          field: 'arrival_date',
          sortable: true,
          formatter: this.fullDateFormatter,
          title: I18n.t('arrival_date', { scope: 'manual_registration.column_fields' }),
        },
        {
          field: 'departure_date',
          sortable: true,
          formatter: this.fullDateFormatter,
          title: I18n.t('departure_date', { scope: 'manual_registration.column_fields' }),
        },
        {
          field: 'additional_dependents_adults',
          sortable: true,
          title: I18n.t('additional_dependents_adults', {
            scope: 'manual_registration.column_fields',
          }),
        },
        {
          field: 'additional_dependents_children',
          sortable: true,
          title: I18n.t('additional_dependents_children', {
            scope: 'manual_registration.column_fields',
          }),
        },
        {
          field: 'issued_date',
          sortable: true,
          formatter: this.fullDateFormatter,
          title: I18n.t('issued_date', { scope: 'manual_registration.column_fields' }),
        },
      ],
    }
  }

  showLink(value, row) {
    return DOMPurify.sanitize(
      `<a href='/manual_registrations/manual_registrations/${row.uid}' class='text-secondary'>${value}</a>`
    )
  }

  booleanFormatter(value) {
    return I18n.t(value ? 'true' : 'false', { scope: 'manual_registration.values' })
  }

  fullMoneyFormatter(value) {
    if (value) {
      return moneyFormatter(value)
    }
    return ''
  }

  fullDateFormatter(value) {
    if (value) {
      return dateFormatter(value)
    }
    return ''
  }

  statusFormatter(value) {
    return I18n.t(value, { scope: 'manual_registration.status' })
  }

  mergeFormParams(params) {
    params.status = $('#status').val()
    params.accomodation_provider_id = $('#accomodation_provider_id').val()
    params.search = $('#search').val()
    return params
  }

  search(event) {
    event.preventDefault()
    this.filterManualRegistrations()
  }

  resetSearch(event) {
    $('#search').val('')
    this.search(event)
  }

  filterManualRegistrations() {
    const limit = '&limit=' + document.querySelector('.page-size').innerText
    const params = $(this.formTarget).serialize() + limit
    this.loadingIndicatorTarget.classList.remove('d-none')
    fetch('/manual_registrations/manual_registrations.json?' + params)
      .then((response) => {
        this.loadingIndicatorTarget.classList.add('d-none')
        if (response.ok) return response.json()
        else throw response.json()
      })
      .then((data) => {
        window.jQuery(this.tableTarget).bootstrapTable('load', data)
      })
      .catch((error) => {
        Promise.resolve(error).then((message) => alert(message.error))
      })
  }
}

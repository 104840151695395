import { Controller } from 'stimulus'
import { csrfHeader } from '../../src/javascripts/csrf_header'
import tinymce from 'tinymce/tinymce'

export default class extends Controller {
  static targets = ['form', 'select', 'mailTemplateLink']

  connect() {
    this.bindTemplateSelectionEvent()
  }

  bindTemplateSelectionEvent() {
    this.selectTarget.addEventListener('change', (event) => {
      const id = event.target.value

      if (id) {
        fetch(`/mail_templates/${id}.json`)
          .then((response) => response.json())
          .then((data) => {
            this.subjectInput.value = data.subject
            tinymce.get(this.textInputId).setContent(data.text)
            this.updateMailTemplateInputAndLink(data.uid)
          })
      } else {
        this.updateMailTemplateInputAndLink(null)
      }
    })
  }

  savePreset(event) {
    const name = prompt(I18n.t('enter_template_name', { scope: 'mail_templates' }))
    const communeId = event.target.dataset.communeId
    if (!name) return

    const data = this.mergeTemplateParams(name, communeId)
    const controller = this

    fetch('/mail_templates.json', { method: 'post', body: data, headers: this.defaultHeaders })
      .then((response) => {
        if (response.ok) return controller.proceedCreatedTemplate(response)
        else return response.text()
      })
      .then((message) => alert(message))
  }

  mergeTemplateParams(name, communeId) {
    const templateParams = {
      name: name,
      commune_id: communeId,
      subject: this.subjectInput.value,
      text: tinymce.get(this.textInputId).getContent(),
    }
    return JSON.stringify({ mail_template: templateParams })
  }

  async proceedCreatedTemplate(response) {
    const data = await response.json()
    const option = new Option(data.name, data.uid, true, true)
    this.selectTarget.add(option)
    this.updateMailTemplateInputAndLink(data.uid)
    return this.successMessage
  }

  updateMailTemplateInputAndLink(id) {
    this.mailTemplateInput.value = id

    if (id) {
      this.mailTemplateLinkTarget.href = `/mail_templates/${id}/edit#mail_template_filereference`
    } else {
      this.mailTemplateLinkTarget.href = '/mail_templates/new#mail_template_filereference'
    }
  }

  get subjectInput() {
    return this.formTarget.querySelector('#annual_card_notification_subject')
  }

  get mailTemplateInput() {
    return this.formTarget.querySelector('#annual_card_notification_mail_template_id')
  }

  get textInputId() {
    return 'annual_card_notification_text'
  }

  get successMessage() {
    const mailTemplateLabel = I18n.t('one', { scope: 'activerecord.models.mail_template' })
    return I18n.t('saved', { scope: 'flash_messages', model: mailTemplateLabel })
  }

  get defaultHeaders() {
    return Object.assign({}, csrfHeader(), { 'Content-Type': 'application/json' })
  }
}

import { Controller } from 'stimulus'
import { DateTime } from '../src/javascripts/luxon_date_time'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['table']

  connect() {
    this.element.controller = this
  }

  get chartController() {
    return document.getElementById('statistic-charts-controller').controller
  }

  get statisticsController() {
    return document.querySelector("[data-controller='statistics']").controller
  }

  export() {
    const filterDetailsRow = this.tableTarget.querySelector('.d-export-block')
    filterDetailsRow.classList.remove('d-none')
    $(this.tableTarget).table2csv({
      separator: ';',
      quoteFields: false,
      filename: 'WELCMpass Statistik vom ' + DateTime.local().toFormat('dd.MM.yyyy') + '.csv',
    })
    filterDetailsRow.classList.add('d-none')
  }

  parseStatistic() {
    this.setVariables()

    this.html += '<thead>'
    this.iterateHeader()
    this.html += '</thead><tbody>'
    this.iterateRows()
    this.iterateFooter()
    this.html += '</tbody><tfoot>'
    this.insertFilterDetailsRow()
    this.html += '</tfoot>'

    this.tableTarget.innerHTML = this.html
  }

  setVariables() {
    this.html = ''
    this.statisticData = this.chartController.statisticChart.data
    this.format = this.chartController.format
    this.countMethod = this.chartController.countMethod
  }

  iterateHeader() {
    this.html += '<tr><th></th>'
    this.statisticData.datasets.forEach((dataset) => {
      this.html += '<th>' + dataset.label + '</th>'
    })
    this.html += '</tr>'
  }

  insertFilterDetailsRow() {
    // NOTE: this row will only be visible when exporting the table
    this.html += `
      <tr class="d-none d-export-block">
        <td></td>
        <td>${this.statisticsController.filterSummary}</td>
      </tr>
    `
  }

  iterateRows() {
    this.chartController.statisticChart.scales['x-axis-0'].ticks.forEach((x) => {
      this.html += "<tr name='" + x + "'><td>" + x + '</td>'

      this.statisticData.datasets.forEach((dataset) => {
        const point = dataset.data.find((point) => {
          return DateTime.fromISO(point.x).toFormat(this.format) === x
        })
        let value = point ? point.y : 0
        value = this.chartController.formatValue(value, this.countMethod)
        this.html += '<td>' + value + '</td>'
      })

      this.html += '</tr>'
    })
  }

  iterateFooter() {
    this.html +=
      '<tr><td><strong>' +
      I18n.t('general', { scope: 'activerecord.attributes.statistic_filter_preset.extra_graph' }) +
      '</strong></td>'

    this.statisticData.datasets.forEach((dataset) => {
      let sum = 0

      dataset.data.forEach((point) => {
        sum += parseFloat(Number(point.y))
      })

      if (this.countMethod === 'average') {
        sum = parseFloat(this.calculateAverage(sum, dataset.data.length))
      }

      let float_sum = parseFloat(sum).toFixed(2)
      let value = float_sum || 0
      value = this.chartController.formatValue(value, this.countMethod)

      this.html += '<td><strong>' + value + '</strong></td>'
    })

    this.html += '</tr></tbody>'
  }

  calculateAverage(sum, total) {
    return parseFloat(sum) / parseFloat(total)
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'select']

  connect() {
    this.bindTemplateSelectionEvent()
  }

  bindTemplateSelectionEvent() {
    this.selectTarget.addEventListener('change', (event) => {
      const id = event.target.value
      if (!id) return

      fetch(`/price_templates/${id}.json`)
        .then((response) => response.json())
        .then((data) => {
          this.formTarget.querySelector('#price_title').value = data.title
          this.formTarget.querySelector('#price_description').value = data.description
          this.formTarget.querySelector('#price_shorthand').value = data.shorthand
          this.formTarget.querySelector('#price_pricedefinitions_types_id').value =
            data.pricedefinitions_type_id
        })
    })
  }
}
